import React from "react";
import { Helmet } from "react-helmet";

import { Layout, useRequestToJoin } from "@components";
import {
  TopSection,
  SectionTwoCt,
  SectionFiveCt, SliderSection, CompanyHelpSection, ReviewsSection,
} from "@templates/business";
import {ClientsSection} from "@templates/main";
import styled from "styled-components";
import {LAPTOP_MOBILE_POINTER, MOBILE_MINIMOBILE_POINTER} from "@tools/constants";

const BusinessPage = () => {
  const {
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    btnDisabled,
    handlerOfSubmitForm,
    isRequestSuccess,
    setIsRequestSuccess,
    openModalWindow,
    setOpenModalWindow,
  } = useRequestToJoin();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Массовые выплаты физ лицам на карты, сервис мгновенных выплат для юр лиц, масспеймент
        </title>
        <meta
          name="description"
          content="Массовые выплаты физическим лицам на банковские карты ➔ Сервис массовых выплат для юридических лиц ♛ Полная автоматизация выплат и налогов при работе с самозанятыми физлицами"
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/mass-payment"
        />
      </Helmet>

      <Layout disableWidth={true}>
        <MaxWidthBox>
          <TopSection />
          <SectionTwoCt />
          <CompanyHelpSection />
        </MaxWidthBox>
        <ReviewsSection />
        <MaxWidthBox>
        <SliderSection/>
        <ClientsSection/>
        <SectionFiveCt
          phone={phone}
          setPhone={setPhone}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          btnDisabled={btnDisabled}
          handlerOfSubmitForm={handlerOfSubmitForm}
          isRequestSuccess={isRequestSuccess}
          setOpenModalWindow={setOpenModalWindow}
        />
        </MaxWidthBox>
      </Layout>
    </>
  );
};

const MaxWidthBox = styled.div`
  
  margin: 0 auto;
  
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    max-width: 1204px;
    padding: 0 32px;
  }
  @media (min-width: ${MOBILE_MINIMOBILE_POINTER + 1}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    max-width: 609px;
    padding: 0 20px;
  }
  @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
    max-width: 375px;
    padding: 0 16px;
  }
  
`;


export default BusinessPage;
